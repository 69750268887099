const _temp0 = require("./ableton.png");
const _temp1 = require("./amplify.png");
const _temp2 = require("./arduino.png");
const _temp3 = require("./bootstrap.png");
const _temp4 = require("./cc.png");
const _temp5 = require("./css.png");
const _temp6 = require("./django.png");
const _temp7 = require("./flask.png");
const _temp8 = require("./graphql.png");
const _temp9 = require("./html5.png");
const _temp10 = require("./jest.png");
const _temp11 = require("./jquery.png");
const _temp12 = require("./js.png");
const _temp13 = require("./node.png");
const _temp14 = require("./python.png");
const _temp15 = require("./react.png");
const _temp16 = require("./sqlite.png");
const _temp17 = require("./tailwind.png");
module.exports = {
  "ableton": _temp0,
  "amplify": _temp1,
  "arduino": _temp2,
  "bootstrap": _temp3,
  "cc": _temp4,
  "css": _temp5,
  "django": _temp6,
  "flask": _temp7,
  "graphql": _temp8,
  "html5": _temp9,
  "jest": _temp10,
  "jquery": _temp11,
  "js": _temp12,
  "node": _temp13,
  "python": _temp14,
  "react": _temp15,
  "sqlite": _temp16,
  "tailwind": _temp17
}