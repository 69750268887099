import React, { useEffect } from 'react'
import pattern from 'patternomaly'
import { useInView } from 'react-intersection-observer';
import TechStackIcon from './TechStackIcon.js';

const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  scales: {
    y: {
      grid: {
        display: true,
        lineWidth: 1,
        drawBorder: false
      },
      ticks: {
        font: {
          family: "Raleway",
          size: 20,
          weight: 'bold'
        }
      }
    },
    x: {
      grid: {
        drawTicks: false,
        display: false,
        drawBorder: false
      },
      ticks: {
        display: false,
      },
      min: 0,
      max: 10,
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: 'Tech Stack',
    },
  },
};

const labels = ['Python/Django', 'HTML/CSS', 'JavaScript', 'React', 'SQL', 'Adobe CC', 'Ruby on Rails'];

const data = {
  labels: labels,
  datasets: [
    {
      // label: 'none',
      data: [8, 8, 7, 7, 6, 5, 3],
      barThickness: 24,
      borderColor: '#000',
      borderWidth: 2,
      borderRadius: 8,
      backgroundColor: [
        // pattern.draw('line-vertical', '#1f77b4'),
        // pattern.draw('line-vertical', '#ff7f0e'),
        pattern.draw('diagonal-right-left', '#1f77b4'),
        pattern.draw('diagonal-right-left', '#ff7f0e'),
      ]
    },
    // {
    //   data: [10, 10, 10, 10, 10, 10, 10],
    //   barThickness: 6,
    //   backgroundColor: '#999'
    // }
  ],
};
function TechStack(props) {
  const { ref, inView, entry } = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView === true) {
      console.log('ts');
      props.setBgColor('bg-hotpink/80')
      props.setActiveTab('stack')
    }
  }, [inView]);

  return (
    <div ref={ref} id="stack" className="container mx-auto min-h-screen">

      <h1 className="font-raleway font-bold text-center text-pink-100 text-6xl md:text-8xl my-12">
        Stack
      </h1>

      <div className="font-ptsans grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-between mb-8 px-4"> 

        <div className="p-4 md:rounded-xl bg-gray-100"> {/* card1 */}
          <h3 className="font-raleway text-center text-4xl py-4 mb-4">Front end</h3>
          <div className="my-2 grid grid-cols-3 gap-4 text-center justify-items-center">
            <TechStackIcon name="html5" title="HTML" />
            <TechStackIcon name="css" title="CSS" />
            <TechStackIcon name="js" title="JavaScript" />
            <TechStackIcon name="react" title="React" />
            <TechStackIcon name="tailwind" title="Tailwind" />
            <TechStackIcon name="bootstrap" title="Bootstrap" />
          </div>
        </div>

        <div className="p-4 md:rounded-xl bg-gray-100"> {/* card1 */}
          <h3 className="font-raleway text-center text-4xl py-4 mb-4">Back end</h3>
          <div className="my-2 grid grid-cols-3 gap-4 text-center justify-items-center">
            <TechStackIcon name="python" title="Python" />
            <TechStackIcon name="django" title="Django" />
            <TechStackIcon name="flask" title="Flask" />
            <TechStackIcon name="sqlite" title="SQLite" />
            <TechStackIcon name="node" title="Node" />
            <TechStackIcon name="amplify" title="AWS Amplify" />
          </div>
        </div>

        <div className="p-4 md:rounded-xl bg-gray-100">
          <h3 className="font-raleway text-center text-4xl py-4 mb-4">Etc.</h3>
          <div className="my-2 grid grid-cols-3 gap-4 text-center justify-items-center">
            <TechStackIcon name="graphql" title="GraphQL" />
            <TechStackIcon name="jest" title="Jest" />
            <TechStackIcon name="cc" title="Adobe CC" />
            <TechStackIcon name="arduino" title="Arduino" />
            <TechStackIcon name="ableton" title="Ableton Live" />
          </div>
        </div>

      </div>
    </div>

  )
}

export default TechStack