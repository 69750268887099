import React, {useState} from 'react'
import { RoughNotation } from 'react-rough-notation'
import { Link } from 'react-scroll'

function Navbar(props) {
	const { activeTab, setActiveTab } = props
	const [hoverTab, setHoverTab] = useState(false)
	
  	return (
			<nav className="z-10 h-12 foreground sticky top-0 mb-4 pt-2 pb-16 bg-white/60 shadow">
				<div className="container flex items-center mx-auto">
					<div className=" flex-1 flex justify-center mr-auto">
						<a href="#" onMouseOver={() => setHoverTab('andy')} onMouseLeave={() => setHoverTab('')} onClick={() => setActiveTab('andy')} className="">
							<RoughNotation iterations='4' type='underline' color="red" show={hoverTab === 'andy' && activeTab !== 'andy'}>
								<RoughNotation iterations='3' type='box' color="red" show={activeTab === 'andy'}>	
									<h2 className="text-3xl font-bold font-raleway">Andy Little</h2>
									<h6 className="text-md font-raleway">Full stack developer</h6>
								</RoughNotation>
							</RoughNotation>
						</a>
					</div>

					<div className="font-raleway hidden md:flex">
						<a href="#stack" onMouseOver={() => setHoverTab('stack')} onMouseLeave={() => setHoverTab('')} onClick={() => setActiveTab('stack')} className="p-3 rounded-xl" >
								<RoughNotation type='underline' color="red" show={hoverTab === 'stack' && activeTab !== 'stack'}> 	
									<RoughNotation type='box' color="red" show={activeTab === 'stack'}>								
										Tech Stack
									</RoughNotation>
								</RoughNotation>
						</a>
						<a href="#projects" onMouseOver={() => setHoverTab('projects')} onMouseLeave={() => setHoverTab('')} onClick={() => setActiveTab('projects')} className="p-3 rounded-xl">
							<RoughNotation type='underline' color="red" show={hoverTab === 'projects' && activeTab !== 'projects'}>
								<RoughNotation type='box' color="red" show={activeTab === 'projects'}>
									Projects
								</RoughNotation>
							</RoughNotation>
						</a>
						<a href="#experience" onMouseOver={() => setHoverTab('experience')} onMouseLeave={() => setHoverTab('')} onClick={() => setActiveTab('experience')} className="p-3 rounded-xl">
							<RoughNotation type='underline' color="red" show={hoverTab === 'experience' && activeTab !== 'experience'}>
								<RoughNotation type='box' color="red" show={activeTab === 'experience'}>
									Experience
								</RoughNotation>
							</RoughNotation>
						</a>
						<a href="#education" onMouseOver={() => setHoverTab('education')} onMouseLeave={() => setHoverTab('')} onClick={() => setActiveTab('education')} className="p-3 rounded-xl">
							<RoughNotation type='underline' color="red" show={hoverTab === 'education' && activeTab !== 'education'}>
								<RoughNotation type='box' color="red" show={activeTab === 'education'}>
								Education
							</RoughNotation>
						</RoughNotation>
						</a>
					</div>

					<div className="flex flex-1 justify-center">

						<a href="https://github.com/sa48k">
							<svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mx-2 text-gray-700 text-right fill-current" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
						</a>
						<a href="https://www.linkedin.com/in/andy-little-nz/">
							<svg className="w-6 h-6 mx-2 text-gray-700 text-right fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
							</svg>
						</a>
						<a href="mailto:andrewjohnlittle@gmail.com">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2 text-gray-700 fill-none">
								<path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
							</svg>
						</a>
					</div>
			</div>
		</nav>
  	)
}

export default Navbar