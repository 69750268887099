import { RoughNotation, RoughNotationGroup } from "react-rough-notation";
import { RainbowHighlight } from "./RainbowHighlight";
import React, { useEffect } from 'react'
import Profile from '../components/Profile.js'
import { useInView } from 'react-intersection-observer';

function Hero(props) {
    const { ref, inView, entry } = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        if (inView === true) {
            props.setActiveTab('andy')
            props.setBgColor('bg-white')
        }
    }, [inView]);

    const colors = ["#F59E0B", "#84CC16", "#10B981", "#3B82F6", "#E4EA28", "#EBBF04", "#EA8D0C", "#34B06A", "#D83F87", "#44318D", "#E98074"];
    return (
        <div ref={ref} className="h-screen mb-48 md:mb-4">
            <div className="container flex flex-col md:flex-row items-center px-6  mx-auto overflow-hidden">
                <div className="flex flex-col w-full md:w-1/2 mx-auto justify-center md:text-left lg:p-20">
            <RoughNotationGroup show={true}>
                <RainbowHighlight color={colors[8]}>
                    <h1 className="p-2 text-4xl lg:text-8xl font-bold text-pink-200 dark:text-gray-200 my-2">
                        Learner
                    </h1>
                </RainbowHighlight>
                <RainbowHighlight color={colors[9]}>
                <h1 className="p-2 text-4xl lg:text-8xl font-bold text-purple-200 dark:text-gray-200 my-2">
                    Educator
                </h1>
                </RainbowHighlight>
                        <RainbowHighlight color={colors[10]}>
                    <h1 className="p-2 text-4xl lg:text-8xl font-bold text-gray-700 dark:text-gray-200 my-2">
                        Developer
                    </h1>
                </RainbowHighlight>
                    </RoughNotationGroup>
                </div>
                <div className="flex flex-col w-full md:w-1/2 mx-auto items-center md:text-left lg:p-20">
                    <Profile />
                </div> 
            </div>
        </div>
  )
}

export default Hero