import React from 'react'
import * as images from "../../img/logos/*.png"

function TechStackIcon(props) {
  return (
      <div>
          <img src={images[props.name]} className="w-24 h-24 p-2" />
          <p className="font-raleway text-sm">{props.title}</p>
      </div>
  )
}

export default TechStackIcon