import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer';
import amesbury from '../../img/amesbury.jpg'

function Experience(props) {
    const { ref, inView, entry } = useInView({
        threshold: 0.2,
    })

    useEffect(() => {
        if (inView === true) {
            props.setActiveTab('experience')
            props.setBgColor('bg-lilac/80')
        }
    }, [inView])

    return (
        <div ref={ref} id="experience" className="container mx-auto min-h-screen">
            <h1 className="font-raleway font-bold text-center text-6xl md:text-8xl my-12 text-white">Experience</h1>
            <div className="text-center md:text-left text-left flex flex-wrap justify-center gap-8 mb-8">
            
                <div className="w-full lg:w-1/3 px-12 py-6 mx-4 md:rounded-lg bg-gradient-to-t from-hotpink/60 to-hotpink/100 border border-black hover:border-white hover:shadow-xl">
                    <h1 className="text-3xl font-ptsans font-bold mb-2 text-pink-100">Y5-6 Hub Teacher</h1>
                    <h2 className="text-xl font-raleway mb-8">Amesbury School, Wellington</h2>
                    <ul className="list-none md:list-[square] text-md md:text-lg font-ptsans leading-loose pb-8">
                        <li className="list-item">Digital Learning lead</li>
                        <li className="list-item">Innovative team-teaching</li>
                        <li className="list-item">Building cloud-based solutions</li>
                        <li className="list-item">Hybrid on-/offline learning model</li>
                    </ul>
                    <div className="mt-auto">
                        <hr />
                        <h3 className="mt-auto text-right text-md text-purple-100">2019-2021</h3>
                    </div>
                </div>

                {/* <div className="w-3/4 mx-auto p-4 m-6 rounded-xl bg-gray-300">
                    <img className="object-stretch" src={amesbury} />'
                </div> */}

                <div className="w-full lg:w-1/3 px-12 py-6 mx-4 md:rounded-lg bg-gradient-to-t from-hotpink/60 to-hotpink/100 border border-black hover:border-white hover:shadow-xl">
                    <h1 className="text-3xl font-ptsans font-bold mb-2 text-pink-100">Y5-6 Class Teacher</h1>
                    <h2 className="text-xl font-raleway mb-8">Newlands School, Wellington</h2>
                    <ul className="list-none md:list-[square] text-md md:text-lg font-ptsans leading-loose pb-8">
                        <li className="list-item">Digital Learning lead</li>
                        <li className="list-item">Implemented Chromebook rollout</li>
                        <li className="list-item">Maths & science curriculum lead</li>
                        <li className="list-item">Kapa haka, music, sport</li>
                    </ul>
                    <div className="mt-auto">
                        <hr />
                        <h3 className="text-right text-md text-purple-100">2014-2018</h3>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Experience