import React, { useState } from 'react'
// import { useInView } from "react-intersection-observer";
import Navbar from './components/Navbar.js'
import Hero from './components/Hero.js'
import TechStack from './components/TechStack.js'
import Projects from './components/Projects.js'
import Experience from './components/Experience.js'
import Education from './components/Education.js'

export function App() {
  const [bgColor, setBgColor] = useState('bg-white/50');
  const [activeTab, setActiveTab] = useState('andy');

  return <div className={`transition-all duration-1000 ${bgColor}`}>   
    <Navbar activeTab={activeTab} setActiveTab={setActiveTab} />
    <Hero setBgColor={setBgColor} setActiveTab={setActiveTab} />
    <TechStack setBgColor={setBgColor} setActiveTab={setActiveTab} />
    <Projects setBgColor={setBgColor} setActiveTab={setActiveTab} />
    {/* <div className="flex flex-col lg:flex-row"> */}
      <Experience setBgColor={setBgColor} setActiveTab={setActiveTab} />
      <Education setBgColor={setBgColor} setActiveTab={setActiveTab} />
    {/* </div> */}
  </div>
}
