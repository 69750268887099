import React from 'react'
import avatar from "../../img/avatar.jpg"

function Profile() {
    return (
      <>
        {/* <h1 className="p-4 font-raleway font-bold text-4xl md:text-6xl text-blue-800 justify-start">About Me</h1> */}
        <figure className="p-4 ">      
          <img className="md:float-right md:ml-8 mt-8 md:mt-6 mb-4 rounded-full shadow-xl w-32 h-32 md:w-24 md:h-24 lg:w-40 lg:h-40 mx-auto" src={avatar} />
          <p className="mt-4 font-raleway leading-loose mb-2 font-bold text-xl text-justify max-w-lg justify-center ">
            After ten years as a primary teacher in Wellington, I am looking for a change of career.
          </p>
          <p className="font-raleway leading-loose mb-2 text-lg text-justify max-w-lg justify-center ">
            I have been coding since an early age; during my teaching career I built a range of online tools that either support students with their learning, or helped to automate or streamline administrative tasks, saving my teaching colleagues a ton of time.
          </p>
          <p className="font-raleway leading-loose pb-2 text-lg text-justify max-w-lg justify-center ">
            I hope to join a team that builds exciting software and will support me to improve as a professional developer.
          </p>
        </figure>
            </>
            )
}

export default Profile