import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer';

function Education(props) {
    const { ref, inView, entry } = useInView({
        threshold: 0.4,
    })

    useEffect(() => {
        if (inView === true) {
            props.setActiveTab('education')
            props.setBgColor('bg-grey/80')
        }
    }, [inView])

    return (
        <div ref={ref} id="education" className="container mx-auto pb-36">
            <h1 className="font-raleway font-bold text-center text-6xl md:text-8xl my-12 text-white">Education</h1>

            <div className="text-left flex flex-wrap justify-center gap-8 mb-8">

                <div className="w-full lg:w-1/3 px-12 py-6 mx-4 md:rounded-lg bg-gradient-to-t from-lilac/30 to-lilac/100 border border-black hover:border-white hover:shadow-xl">
                    <h1 className="text-3xl font-ptsans font-bold mb-2 text-pink-100">MA Psychology</h1>
                    <br />
                    <ul className="list-none md:list-[square] text-md md:text-lg font-ptsans leading-loose pb-4">
                        <li>Neuropsychology</li>
                        <li>Social Psychology</li>
                        <li>Biological Psychology</li>
                        <li>Language and Cognition</li>
                        <li>Perception and Development</li>
                    </ul>
                </div>

                <div className="w-full lg:w-1/3 px-12 py-6 mx-4 md:rounded-lg bg-gradient-to-t from-lilac/30 to-lilac/100 border border-black hover:border-white hover:shadow-xl">
                    <h1 className="text-3xl font-ptsans font-bold mb-2 text-pink-100">Postgraduate Diploma Teaching</h1>
                    <br />
                    <ul className="list-none md:list-[square] text-md md:text-lg font-ptsans leading-loose pb-4">
                        <li>Primary (Years 0-8)</li>
                    </ul>
                </div>

                <div className="w-full lg:w-1/3 px-12 py-6 mx-4 md:rounded-lg bg-gradient-to-t from-lilac/30 to-lilac/100 border border-black hover:border-white hover:shadow-xl">
                    <h1 className="text-3xl font-ptsans font-bold mb-2 text-pink-100">Postgraduate Certificate Education</h1>
                    <br />
                    <ul className="list-none md:list-[square] text-md md:text-lg font-ptsans leading-loose pb-4">
                        <li>Curriculum: Theory, Research and Practice</li>
                        <li>Teaching Linguistically Diverse Learners</li>
                    </ul>
                </div>

                <div className="w-full lg:w-1/3 px-12 py-6 mx-4 md:rounded-lg bg-gradient-to-t from-lilac/30 to-lilac/100 border border-black hover:border-white hover:shadow-xl">
                    <h1 className="text-3xl font-ptsans font-bold mb-2 text-pink-100">Postgraduate Certificate Applied Practice</h1>
                    <br />
                    <ul className="list-none md:list-[square] text-md md:text-lg font-ptsans leading-loose pb-4">
                        <li>Digital and Collaborative Learning</li>
                    </ul>
                </div>


            </div>






            
        </div>
    )
}

export default Education