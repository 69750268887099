import React, { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer';
import pokeball from "../../img/pokeball.svg"

function Projects(props) {
    const { ref, inView, entry } = useInView({
        threshold: 0.4,
    })

    useEffect(() => {
        if (inView === true) {
            props.setActiveTab('projects')
            props.setBgColor('bg-purp/80')
        }
    }, [inView])

    return (
        <div ref={ref} id="projects" className="container mx-auto min-h-screen pb-24">
            <h1 className="font-raleway font-bold text-center text-6xl md:text-8xl my-12 text-purple-100">Projects</h1>
            <div className="text-left flex flex-wrap justify-evenly gap-8 mb-8">

                <a href="https://suburble.nz" className="relative w-full flex flex-col lg:w-2/5 px-12 py-6 mx-4 bg-gradient-to-t from-purple-500 via-purple-400 to-purple-400 md:rounded-lg border border-black hover:border-white hover:shadow-xl">
                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute top-6 right-10 h-10 w-10 text-purple-100" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                    </svg>
                    <h1 className="text-xl md:text-3xl font-ptsans font-bold mb-2 text-white">Suburble <span className="text-gray-900 font-raleway text-xl">(2022)</span></h1>
                    <h2 className="text-xl font-raleway mb-4">Geolocation game</h2>
                    <p className="text-md font-ptsans mb-8">How well do you know the suburbs of Te Whanganui-a-Tara?</p>
                    <div className="mt-auto">
                        <hr />
                        <h3 className="mt-auto text-right text-md text-purple-100">React, Maps API, AWS</h3>
                    </div>
                </a>

                <a href="https://www.speller.nz" className="relative w-full flex flex-col lg:w-2/5 px-12 py-6 mx-4 bg-gradient-to-t from-purple-500 via-purple-400 to-purple-400 md:rounded-lg border border-black hover:border-white hover:shadow-xl">
                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute top-6 right-10 h-10 w-10 text-pink-100" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                    </svg>
                    <h1 className="text-xl md:text-3xl font-ptsans font-bold mb-2 text-white">Speller <span className="text-gray-900 font-raleway text-xl">(2021)</span></h1>
                    <h2 className="text-xl mb-4">Digital spelling assessment</h2>
                    <p className="text-md font-ptsans mb-8">Teachers can record audio for spelling words, along with example sentences. Students complete the assessment on their personal device, at their own pace. Teachers and students receive instant feedback.</p>
                    <div className="mt-auto">
                        <hr />
                        <h3 className="mt-auto text-right text-md text-pink-100">Django, jQuery, SQL</h3>
                    </div>
                </a>

                <a href="https://github.com/sa48k/pokemon-test" className="relative w-full flex flex-col lg:w-2/5 px-12 py-6 mx-4 bg-gradient-to-t from-purple-500 via-purple-400 to-purple-400 md:rounded-lg border border-black hover:border-white hover:shadow-xl">
                    <img src={pokeball} className="absolute top-6 right-10 h-9 w-9 text-white" viewBox="0 0 20 20" />
                        
                    <h1 className="text-xl md:text-3xl font-ptsans font-bold mb-2 text-white">React-Pokedex <span className="text-gray-900 font-raleway text-xl">(2022)</span></h1>
                    <h2 className="text-xl mb-4">Technical test</h2>
                    <p className="text-md font-ptsans mb-8">Browse the original 150 Pokemon. Filter by type, or click for details.</p>
                    <div className="mt-auto">
                        <hr />
                        <h3 className="mt-auto text-right text-md text-pink-100">React Testing Library, TailwindCSS, GraphQL</h3>
                    </div>
                </a>

                <a href="http://yesikan.nz" className="relative w-full flex flex-col lg:w-2/5 px-12 py-6 mx-4 bg-gradient-to-t from-purple-500 via-purple-400 to-purple-400 md:rounded-lg border border-black hover:border-white hover:shadow-xl">
                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute top-6 right-10 h-10 w-10 text-green-100" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2H6zm1 2a1 1 0 000 2h6a1 1 0 100-2H7zm6 7a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zm-3 3a1 1 0 100 2h.01a1 1 0 100-2H10zm-4 1a1 1 0 011-1h.01a1 1 0 110 2H7a1 1 0 01-1-1zm1-4a1 1 0 100 2h.01a1 1 0 100-2H7zm2 1a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm4-4a1 1 0 100 2h.01a1 1 0 100-2H13zM9 9a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zM7 8a1 1 0 000 2h.01a1 1 0 000-2H7z" clipRule="evenodd" />
                    </svg>
                    <h1 className="text-xl md:text-3xl font-ptsans font-bold mb-2 text-white">YesIKAN <span className="text-gray-900 font-raleway text-xl">(2015)</span></h1>
                    <h2 className="text-xl mb-4">Number knowledge tool</h2>
                    <p className="text-md font-ptsans mb-8">Generates 'quick 10' maths questions for use in class. Aligned with the widely-used IKAN assessment. Sees over 100,000 hits each month.</p>
                    <div className="mt-auto">
                        <hr />
                        <h3 className="mt-auto text-right text-md text-green-100">Flask, Bootstrap, Git</h3>
                    </div>
                </a>

                <a href="https://www.randomometron.net" className="relative w-full flex flex-col lg:w-2/5 px-12 py-6 mx-4 bg-gradient-to-t from-purple-500 via-purple-400 to-purple-400 md:rounded-lg border border-black hover:border-white hover:shadow-xl">
                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute h-10 w-10 top-6 right-10 text-white" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                    </svg>
                    <h1 className="text-xl md:text-3xl font-ptsans font-bold mb-2 text-white">Randomometron <span className="text-gray-900 font-raleway text-xl">(2014)</span></h1>
                    <h2 className="text-xl mb-4">Classroom management</h2>
                    <p className="text-md font-ptsans mb-8">Select 'volunteers'; make small groups or split a class into teams; launch a countdown timer.</p>
                    <div className="mt-auto">
                        <hr />
                        <h3 className="mt-auto text-right text-md text-blue-100">HTML, Python, CSS, JavaScript, WSGI</h3>
                    </div>
                </a>
            </div>
        </div>         
  )
}

export default Projects